import './CustomText.scss'

function CustomText({ className, children }) {
   return (
      <div className={`typical_text ${className ? className : ''}`}>
         {children}
      </div>
   );
}

export default CustomText;