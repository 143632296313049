import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import FrontPage from './pages/FrontPage';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Privacy from "./pages/Privacy";
import './App.scss';


const router = createBrowserRouter([
    {
        path: "/",
        element: <FrontPage />,
    }, {
        path: "/privacy",
        element: <Privacy />,
    },
]);

function App() {
    return (
        <div className="App">
            <Header />
            <main>
                <RouterProvider router={router} />
            </main>
            <Footer />
        </div>
    );
}

export default App;
