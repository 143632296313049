import React, { useEffect, useRef } from 'react'
import Advantages from "../components/FrontPage/Advantages/Advantages";
import BlackBlock from "../components/FrontPage/BlackBlock/BlackBlock";
import DataDriven from "../components/FrontPage/DataDriven/DataDriven";
import FirstBlock from "../components/FrontPage/FirstBlock/FirstBlock";
import ReffectionNumber from "../components/FrontPage/ReffectionNumber/ReffectionNumber";
import Reviews from "../components/FrontPage/Reviews/Reviews";
import Warranty from "../components/FrontPage/Warranty/Warranty";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Products from '../components/FrontPage/Products/Products';

gsap.registerPlugin(ScrollTrigger);

function FrontPage() {
   const wrapSection = useRef(null)

   useEffect(() => {
      const allSection = wrapSection.current.querySelectorAll('section')
      allSection.forEach((item, index) => {
         if (index !== 0) {
            gsap.set(item, {
               opacity: 0,
               y: 50,
            })
            ScrollTrigger.create({
               trigger: item,
               start: "top bottom",
               end: "bottom bottom",
               onEnter: () => {
                  gsap.to(item, {
                     opacity: 1,
                     y: 0,
                     duration: .6,
                     onComplete: () => {
                        item.style.transform = ''
                     }
                  })
               }
            });
         }
      })
   }, []);

   return (
      <div className="front_page" ref={wrapSection}>
         <FirstBlock />
         <Advantages />
         <BlackBlock />
         <DataDriven />
         <ReffectionNumber />
         <Products />
         <Reviews />
         <Warranty />
      </div>
   );
}

export default FrontPage;