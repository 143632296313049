import BtnButton from '../utilities/Btn/BtnButton';
import './Header.scss'
import { useMediaQuery } from 'react-responsive'
import BtnLink from "../utilities/Btn/BtnLink";

function Header() {
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
   return (
      <header className="header">
         <div className="header_wrap">
            <a href="#" className="header_wrap-logo" aria-label='Логотип'>
               <img src="./img/header/logo.png" alt="Логотоип" />
            </a>
            <nav className='header_wrap_nav'>
               <ul>
                  <li>
                     <a href="#products">
                        продукты
                     </a>
                  </li>
                  <li>

                     <BtnLink link={'./img/pdf/services.pdf'} >стоимость</BtnLink>
                  </li>
                  <li>
                     <a href="#footer">
                        Контакты
                     </a>
                  </li>
               </ul>
            </nav>
            <div className="header_wrap_right">

               <BtnLink
                  link={'https://t.me/shans_reffection'}
                  label={'Telegram'}
                  target={'_blank'}
                  className={'header_wrap_right-btn'}
               >
                  <div className="icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M11.823 8.12506C12.0313 7.98617 12.1702 7.9688 12.2397 8.07297C12.3091 8.17714 12.257 8.31603 12.0834 8.48964C10.4688 10.1563 9.2709 11.0938 8.59381 11.8751C8.28131 12.2223 8.36811 12.5695 8.85423 12.9168L12.0834 15.1043C13.3855 15.9897 13.8022 15.2605 13.9584 14.3751C14.5487 11.2154 14.9654 8.59381 15.2084 6.51046C15.3126 5.72921 15.0522 5.31254 14.0626 5.62504C13.1251 5.90282 9.75701 7.30908 3.95836 9.84382C2.8646 10.2605 3.07294 10.7292 3.69794 10.9376C4.32295 11.1459 4.7917 11.3022 5.4167 11.4584C6.04171 11.6147 6.45838 11.6667 7.18755 11.198M10.0522 0C12.6998 0.01379 15.2339 1.07701 17.0988 2.95649C18.9636 4.83598 20.007 7.37831 20.0001 10.026C19.9932 12.6736 18.9366 15.2105 17.062 17.0802C15.1873 18.95 12.6477 20 10.0001 20C7.3524 20 4.81279 18.95 2.93817 17.0802C1.06354 15.2105 0.00692896 12.6736 3.39569e-05 10.026C-0.00686104 7.37831 1.03653 4.83598 2.90138 2.95649C4.76624 1.07701 7.30035 0.01379 9.94798 0" fill="white" />
                     </svg>
                  </div>
                  telegram
               </BtnLink>
               {isMobile &&
                  <div className="header_wrap_right-burger btn light_gray">
                     Меню
                  </div>
               }
            </div>
         </div>
      </header>
   );
}

export default Header;