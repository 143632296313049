import './infoblock.scss'

function infoblock() {
    return (
        <section className="infoblock">
            <div className={'container'}>
                <h1>Политика обработки персональных данных</h1>
                <div className={'wrap'}>
                    <div className={'box_policy'}>
                        <h3>1. Общие положения</h3>
                        <p>1.1. Настоящая Политика разработана во исполнение требований Конституции Российской
                            Федерации, п.
                            2 ч. 1 и ч. 2 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных»
                            (далее
                            — Закон о персональных данных), Трудового кодекса Российской Федерации, Федерального закона
                            от
                            27.07.2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите информации» и с
                            учетом положений действующегозаконодательства. Настоящая Политика определяет основные
                            принципы,
                            цели, условия и способы обработки персональных данных, перечни субъектов обработки
                            персональных
                            данных и категорииобрабатываемых Оператором персональных данных, права и обязанности
                            Оператора
                            при обработке персональных данных, права субъектов персональных данных.
                            <p></p>
                            1.2. Настоящая Политика действует в отношении всех обрабатываемых персональных данных,
                            полученных как до издания соответствующей Политики, так и после, за исключениемслучаев,
                            когда
                            положения настоящей Политики не могут быть распространены на отношения по обработке и защите
                            персональных данных, полученных до ее утверждения.
                        </p>
                        <h3>2. Термины:</h3>
                        <p>Персональные данные (ПД) — любая информация, относящаяся к прямо или косвенно определенному
                            или
                            определяемому физическому лицу (субъекту персональных данных);</p><p>

                        Оператор – ООО «Виа Пуш» (ОГРН 1177746561442, 127015, г. Москва, МО Бутырский, ул. Большая
                        Новодмитровская, д. 23, стр. 3, эт. 7, пом. I, ком.13), юридическое лицо, самостоятельно или
                        совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а
                        также определяющее цели обработки персональных данных, состав персональных данных, подлежащих
                        обработке, действия (операции), совершаемые с персональными данными;</p><p>
                        Обработка ПД — любое действие (операция) или совокупность действий (операций), совершаемых с
                        использованием средств автоматизации или без использования таких средств с персональными
                        данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                        изменение), извлечение, использование, передачу (распространение, предоставление, доступ),
                        обезличивание, блокирование, удаление, уничтожение ПД;</p><p>
                        Автоматизированная обработка ПД — обработка ПД с помощью средств вычислительной техники;
                        Распространение ПД — действия, направленные на раскрытие ПД неопределенному кругу лиц;
                        Предоставление ПД — действия, направленные на раскрытие ПД определенному лицу или определенному
                        кругу лиц;</p><p>
                        Блокирование ПД — временное прекращение обработки ПД (за исключением случаев, если обработка
                        необходима для уточнения ПД);</p><p>
                        Уничтожение ПД — действия, в результате которых становится невозможным восстановить содержание
                        ПД в информационной системе ПД и (или) в результате которых уничтожаются материальные носители
                        ПД;</p><p>
                        Обезличивание ПД — действия, в результате которых становится невозможным без использования
                        дополнительной информации определить принадлежность ПД конкретному субъекту;</p><p>
                        Информационная система ПД — совокупность содержащихся в базах данных ПД и обеспечивающих их
                        обработку информационных технологий и технических средств;</p><p>
                        Трансграничная передача ПД — передача ПД на территорию иностранного государства органу власти
                        иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</p>
                        <h3>3. Основные принципы обработки персональных данных</h3>
                        <p>3.1. Обработка ПД основывается на принципе законности обработки ПД. Обработка ограничивается
                            достижением конкретных, заранее определенных и законных целей.</p><p>
                        3.2. При обработке ПД обеспечивается их точность, достаточность и актуальность.</p><p>
                        3.3. Обработанные ПД подлежат уничтожению после достижения целей обработки или в случае утраты
                        необходимости в достижении этих целей, если иное не предусмотрено федеральным законом, а также
                        по
                        заявлению субъекта ПД.</p>
                        <h3>4. Основные цели обработки персональных данных</h3>
                        <p>
                            4.1. Одной из основных целей обработки ПД является подготовка, заключение, исполнение и
                            прекращение гражданско-правовых договоров с контрагентами.
                        </p><p>
                        4.2. Осуществляя обработку ПД, Оператор обеспечивает соблюдение требований законодательства
                        Российской Федерации.
                    </p><p>
                        4.3. Настоящим Оператор гарантирует соблюдение конфиденциальности информации при обработке ПД.
                    </p><p>
                        4.4. Обработка ПД допускается при наличии явно выраженного согласия субъекта ПД либо получена от
                        третьего лица с получением согласия субъекта ПД.
                    </p><p>
                        4.5. Не допускается обработка ПД, которая не соответствует законным целям.
                    </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default infoblock;