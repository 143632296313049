import BtnLink from '../../utilities/Btn/BtnLink';
import CustomText from '../../utilities/CustomText/CustomText';
import './Products.scss'
import ProductsItem from './ProductsItem';
import ProductsItemContent from './ProductsItemContent';
import ProductsItemImage from './ProductsItemImage';
import ProductsItemTitle from './ProductsItemTitle';
import ProductsList from './ProductsList';

function Products({className}) {

    return (
        <section id={'products'} className={`products ${className ? className : ''}`}>
            <div className="container">
                <div className="products_head">
                    <h2 className='products_head-title section_title'>
                        Продукты Reffection
                    </h2>
                    <BtnLink link={'./img/pdf/services.pdf'}  className='light_gray products_head-btn'>Все условия и цены</BtnLink>
                </div>
                <ProductsList>
                    <ProductsItem classCol='col-xl-3'>
                        <ProductsItemImage link={'./img/products/retargeting.svg'}
                                           alt={'Retargeting to contact or call'}/>
                        <ProductsItemContent>
                            <ProductsItemTitle>
                                Retargeting to&nbsp;contact&nbsp;/&nbsp;call
                            </ProductsItemTitle>
                            <CustomText className='white small products_wrap_item-text'>
                                <p>
                                    Получаем контактные и поведенческие данные по функциональному коду на сайте
                                    рекламодателя

                                </p>
                            </CustomText>
                            <div className='products_wrap_item-btn'>
                                <BtnLink className='white ' link={'./img/pdf/retarget.pdf'} target={'_blank'}>
                                    тарифы
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14" fill="none">
                                            <path d="M0 7H14" stroke="#121212"/>
                                            <path d="M7 14L7 2.68221e-07" stroke="#121212"/>
                                        </svg>
                                    </div>
                                </BtnLink>
                            </div>
                        </ProductsItemContent>
                    </ProductsItem>
                    <ProductsItem classCol='col-xl-3'>
                        <ProductsItemImage link={'./img/products/segments.svg'} alt={'Target segments'}/>
                        <ProductsItemContent>
                            <ProductsItemTitle>
                                Target segments
                            </ProductsItemTitle>
                            <CustomText className='white small products_wrap_item-text'>
                                <p>
                                    Подбираем аудиторию по всей вертикали отечественного онлайн спроса на
                                    продукты/услуги рекламодателя через DMP фильтрацию собственной Big Data

                                </p>
                            </CustomText>
                            <div className='products_wrap_item-btn'>
                                <BtnLink className='white ' link={'./img/pdf/target-data.pdf'} target={'_blank'}>
                                    тарифы
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14" fill="none">
                                            <path d="M0 7H14" stroke="#121212"/>
                                            <path d="M7 14L7 2.68221e-07" stroke="#121212"/>
                                        </svg>
                                    </div>
                                </BtnLink>
                            </div>
                        </ProductsItemContent>
                    </ProductsItem>
                    <ProductsItem classCol='col-xl-3'>
                        <ProductsItemImage link={'./img/products/call-center.svg'} alt={'Call center telephony'}/>
                        <ProductsItemContent>
                            <ProductsItemTitle>
                                Call center telephony
                            </ProductsItemTitle>
                            <CustomText className='white small products_wrap_item-text'>
                                <p>
                                    Обходим спам-фильтры операторов и обеспечиваем максимально возможный (до 90%)
                                    показатель эффективности дозвона до заинтересованных потенциальных потребителей </p>
                            </CustomText>
                            <div className='products_wrap_item-btn'>
                                <BtnLink className='white ' link={'./img/pdf/callcenter.pdf'} target={'_blank'}>
                                    тарифы
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14" fill="none">
                                            <path d="M0 7H14" stroke="#121212"/>
                                            <path d="M7 14L7 2.68221e-07" stroke="#121212"/>
                                        </svg>
                                    </div>
                                </BtnLink>
                            </div>
                        </ProductsItemContent>
                    </ProductsItem>
                    <ProductsItem classCol='col-xl-3'>
                        <ProductsItemImage link={'./img/products/data-enrichment.svg'} alt={'Data Enrichment'}/>
                        <ProductsItemContent>
                            <ProductsItemTitle>
                                Data Enrichment
                            </ProductsItemTitle>
                            <CustomText className='white small products_wrap_item-text'>

                                <p>
                                    Обогащаем клиентские карточки в базе CRM заказчика контактными данными и данными о
                                    заинтересованности в продукте или услуге
                                </p>
                            </CustomText>
                            <div className='products_wrap_item-btn'>
                                <BtnLink className='white ' link={'./img/pdf/data.pdf'} target={'_blank'}>
                                    тарифы
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14" fill="none">
                                            <path d="M0 7H14" stroke="#121212"/>
                                            <path d="M7 14L7 2.68221e-07" stroke="#121212"/>
                                        </svg>
                                    </div>
                                </BtnLink>
                            </div>
                        </ProductsItemContent>
                    </ProductsItem>
                </ProductsList>
            </div>
        </section>
    );
}

export default Products;